import { template as template_fdeb451ef33b445fa1289722e8a6c849 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fdeb451ef33b445fa1289722e8a6c849(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
