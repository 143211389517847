import { template as template_2ed1608ac9dc4e9cb4c7486d63e960c9 } from "@ember/template-compiler";
const FKLabel = template_2ed1608ac9dc4e9cb4c7486d63e960c9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
