import { template as template_a06ff2257d8e488b982e8a25685c5fee } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_a06ff2257d8e488b982e8a25685c5fee(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
