import { template as template_e47829ff38c345449bb5d01f7041b9d7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e47829ff38c345449bb5d01f7041b9d7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
