import { template as template_a0dce3f89b1044ee93d46b634d6ed8ae } from "@ember/template-compiler";
const SidebarSectionMessage = template_a0dce3f89b1044ee93d46b634d6ed8ae(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
