import { template as template_f71885f7a6814905b90cc1882748a9ac } from "@ember/template-compiler";
const FKText = template_f71885f7a6814905b90cc1882748a9ac(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
